import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum Answer {
  LessThanTwo = "LessThanTwo",
  BetweenTwoOrFour = "BetweenTwoOrFour",
  MoreThanFour = "MoreThanFour",
  NeverArrived = "NeverArrived"
}

// Define a type for the slice state
interface ThreeHourState {
    answer: Answer | null
    showLessThanThreeHourDialog: boolean
}

// Define the initial state using that type
const initialState: ThreeHourState = {
    answer: null,
    showLessThanThreeHourDialog: true
}

export const threeHourSlice = createSlice({
    name: 'threeHour',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setAnswer: (state, action: PayloadAction<Answer | null>) => {
            state.answer = action.payload
        },
        setShowLessThanThreeHourDialog: (state, action: PayloadAction<boolean>) => {
            state.showLessThanThreeHourDialog = action.payload
        },
        clear: (state) => {
            state = initialState
        },
    }
})

export const { setAnswer, clear, setShowLessThanThreeHourDialog } = threeHourSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default threeHourSlice.reducer
