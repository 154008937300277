import React, { useState, useEffect, ChangeEvent } from 'react';
import FormContainer from './../../basic/FormContainer';
import { TextField, Grid, Autocomplete, Collapse, Typography } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { da } from "date-fns/locale"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { setAirline, setFlightNumPrefix, setFlightNum, setDate, setSpecificFlightContinue, setEc261 } from './SpecificFlightSlice';
import { setFlight } from "../ChooseFlight/ChooseFlightSlice"
import { useAppSelector, useAppDispatch } from '../../../hooks';
import FlightIcon from '@mui/icons-material/Flight';
import { getCaseId, searchAirlines } from "../../../requests";
import { checkCompensationEu261 } from "../../../utils";
import { setCaseId, setToken } from "../CompositionPage/CompositionSlice";
import { LoadingButton } from "@mui/lab";

import HeaderTypography from "../../basic/HeaderTypography"
import { PrefixTextField, FlightNumTextField } from "../../basic/TextFields"

import { useMediaQuery } from "react-responsive"
import TagManager from 'react-gtm-module';

interface AutocompleteOption {
  name: string,
  iata: string,
  ec261: boolean,
  id: string,
}

const SpecificFlightPage = (props: {}) => {
    const { flightNumPrefix, flightNum, date, isValidated, specificFlightContinue } = useAppSelector(state => state.specificFlight)
    const [autocompOptions, setAutocompOptions] = useState<Array<AutocompleteOption>>([])
    const [ec261, setec261,] = useState<boolean | null>(null)
    const [loading, setLoading] = useState<boolean>(false)

    const dispatch = useAppDispatch()

    const flight = useAppSelector(state => state.chooseFlight.flight)
    const firstCarrier = useAppSelector(state => state.firstCarrier.airline)
    const depRoute = useAppSelector(state => state.route.depAirport)
    const arrRoute = useAppSelector(state => state.route.arrAirport)
    const caseId = useAppSelector(state => state.composition.caseId)
    const token = useAppSelector(state => state.composition.reqToken)

    let depAirport, arrAirport;

    if (flight !== null) {
        depAirport = flight.from.name
        arrAirport = flight.to.name
    }

    const checkEU261 = () => {
      if (depRoute !== null && arrRoute !== null && ec261 !== null) {
        return checkCompensationEu261({...depRoute, country: depRoute.countryCode}, {
          ...arrRoute,
          country: arrRoute.countryCode
        }, ec261)
      }
      return {eu_eligible:false, dk_eligible:false}
    }


    const handleAirline = (e: object, value: AutocompleteOption | null) => { // FIXME: A type for the value that indicates it is an airline
      // We set the airline and if that airline also has an iata, then we set that as well.
      if (value !== null) {
        setec261(value.ec261)
        dispatch(setAirline(value.id))
        // @ts-ignore
        if (e.target.iata !== null) {
          dispatch(setFlightNumPrefix(value.iata))
        }
      }
      else {
        dispatch(setAirline(""))
        dispatch(setFlightNumPrefix(""))
      }
    }
    useEffect(() => {
        if (flight === null && depRoute !== null && arrRoute !== null) {
            dispatch(setFlight({from: depRoute, to: arrRoute}))
        }
        if (firstCarrier !== null) {
          if (flight === null || depRoute?.iata === flight?.from.iata) {
            // @ts-ignore
        setAutocompOptions([firstCarrier])
        // @ts-ignore
        handleAirline({"target": firstCarrier}, firstCarrier)
          }
        }
    }, [])


    const handleAutocompReq = async (e: object) => {
      // @ts-ignore
      const data = await searchAirlines(e.target.value)
      if (data.ok)
        setAutocompOptions(data.val)
    }

    const handleFlightNumPrefix = (e: ChangeEvent<HTMLInputElement>) => {
      if (flightNumPrefix === "")
        dispatch(setFlightNumPrefix(e.target.value))
    }

    const handleFlightNum = (e: ChangeEvent<HTMLInputElement>) => {
      const flightNum: string = e.target.value.replace(/\D/, "")

      if (flightNum.length <= 4) dispatch(setFlightNum(flightNum))
    }

    const makeIdRequestAndSave = async () => {
      const res = await getCaseId()
      const callSuccess = res.ok ? "success" : "failure"
      TagManager.dataLayer({
        dataLayer: {
          event: "create_case_in_db_call",
          callSuccess: callSuccess
        }
      })
      if (res.ok){
        dispatch(setCaseId(res.val.caseId))
        dispatch(setToken(res.val.token))

      } else {
        alert(res.val.message)
      }
    }

    const handleSpecificFlightContinue = async () => {
      setLoading(true)
      const eligible = checkEU261()
      dispatch(setEc261(eligible))
      // Send create case request
      if (eligible.eu_eligible && eligible.dk_eligible) {
        if (token === "" || caseId === "") await makeIdRequestAndSave()
        dispatch(setSpecificFlightContinue(isValidated))
      }
      setLoading(false)
    }

    const isDesktopOrLaptop = useMediaQuery({
      query: '(min-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    
    return <React.Fragment>
            <FormContainer willScroll>
              <HeaderTypography>Hvem fløj du med og hvilken dato?</HeaderTypography>
              { isDesktopOrLaptop || isBigScreen ?
                <Typography variant="body1" marginBottom="20px">{depAirport} <FlightIcon fontSize='large' sx={{ transform: "rotate(90deg)", marginBottom: "-10px", marginLeft: "3px", marginRight: "3px", color:"primary.main"  }}/> {arrAirport}</Typography>
                :
                <>
                  <Typography variant="body1" sx={{ margin: "10px"}}>{depAirport}</Typography>
                  <FlightIcon fontSize='large' sx={{ transform: "rotate(90deg)", marginBottom: "-10px", color:"primary.main"  }}/>
                  <Typography variant="body1" sx={{ margin: "10px"}}>{arrAirport}</Typography>
                </>
              }
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <Autocomplete
                    options={autocompOptions}
                    noOptionsText={"Søg efter et flyselskab"}
                    data-testid="specificFlight-autocomplete"
                    fullWidth
                    onChange={handleAirline}
                    onInputChange={handleAutocompReq}
                    getOptionLabel={(option) => `${option.name} (${option.iata})`}
                    renderInput={(params) => <TextField {...params}
                                                label="Flyselskab"
                                                variant="outlined"
                                />}
                  />
                </Grid>
                <Grid item md={4}>
                  <Grid container spacing={0}>
                    <Grid item md={3} xs={3}>
                      <PrefixTextField
                                 fullWidth
                                 type="text"
                                 label="Fly prefix"
                                 variant="outlined"
                                 value={flightNumPrefix}
                                 onChange={handleFlightNumPrefix}
                                 />
                    </Grid>
                    <Grid item md={9} xs={9}>
                      <FlightNumTextField
                                 fullWidth
                                 type="text"
                                 label="Fly nummer"
                                 variant="outlined"
                                 value={flightNum}
                                 onChange={handleFlightNum}
                                 />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={8} xs={12}>
                  <LocalizationProvider 
                    dateAdapter={AdapterDateFns}
                    locale={da}
                    localeText={{ cancelButtonLabel: "annuller", previousMonth: "Forrige måned", nextMonth: "Næste måned" }}
                  >
                    <MobileDatePicker
                      label="Dato"
                      inputFormat="dd/MM/yy"
                      disableFuture
                      renderInput={(params) => <TextField fullWidth {...params} />}
                      value={date}
                      onChange={(date) => {
                          if (date !== null) {
                              // FIXME: Remove this
                              // const dateFields = date.toString().split(" ")
                              // const dateString = [dateFields[0], dateFields[1], dateFields[2], dateFields[3]].join(" ") + " 00:00:00 GMT+0000"
                              // const dateFixed = new Date(dateString)
                              // const dateString = `${dateFixed.getDate()}/${dateFixed.getMonth()+1}/${dateFixed.getFullYear()}`
                              dispatch(setDate(date.toString()))
                              // dispatch(setDate(date.toLocaleString()))
                          }
                          else dispatch(setDate(null))
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </FormContainer>
            <Collapse in={!specificFlightContinue &&
                          isValidated}>
              <FormContainer>
                <LoadingButton loading={loading} disabled={loading} variant="contained"
                        onClick={handleSpecificFlightContinue}>
                  Fortsæt
                </LoadingButton>
              </FormContainer>
            </Collapse>
           </React.Fragment>
}

export default SpecificFlightPage
