import React, { useState, SyntheticEvent } from 'react'
import FormContainer from '../../basic/FormContainer'
import { Button, TextField, Grid, Collapse, Autocomplete } from '@mui/material'
import { TransitionGroup } from 'react-transition-group'
import { useAppSelector, useAppDispatch } from '../../../hooks'
import { setIthAirport, addAirport, removeLastAirport } from "./AddStopOverSlice"
import { Airport } from "../../../utils"

// FIXME: This is temporary
import HeaderTypography from "../../basic/HeaderTypography"
import { searchAirports } from "../../../requests"

interface AutocompleteOption {
    name: string,
    iata: string,
    country: string,
    city: string,
}

const AddStopOver = (props:{}) => {
    const [autocompOptions, setAutocompOptions] = useState<Array<AutocompleteOption>>([])
    const airports = useAppSelector(state => state.addStopOver.stopOverArray)
    const dispatch = useAppDispatch()

    const handleStopOver = (i: number) => (e: object, value: AutocompleteOption | null) => { 
        if (value !== null) {
            const airport: Airport = { name: value.name, iata: value.iata, ec261:true, countryCode:"" }
            dispatch(setIthAirport({val: airport, i}))
        }
        else dispatch(setIthAirport({val: null, i}))
    }
    const handleAutocompReq = async (e: object) => {
        // @ts-ignore
        const data = await searchAirports(e.target.value)
        if (data.ok)
            setAutocompOptions(data.val)
    }

    return <FormContainer>
            <Grid container spacing={2} >
                <Grid item md={12}>
                    <HeaderTypography>Hvor skulle du mellemlande?</HeaderTypography>
                </Grid>
                <Grid item md={12} xs={12}>
                    <TransitionGroup>
                        {
                            airports.map((airport, i) => (
                                <Collapse key={"stop-over-id-"+i}>
                                    <Autocomplete 
                                      options={autocompOptions}
                                      noOptionsText={"Søg efter en lufthavn"}
                                      data-testid="routeinfo-stopover-autocomplete"
                                      filterOptions={(x) => x}
                                      onChange={handleStopOver(i)}
                                      onInputChange={handleAutocompReq}
                                      getOptionLabel={(option) => `${option.name} (${option.iata}), ${option.city}, ${option.country}`}
                                      renderInput={(params) => <TextField {...params} 
                                                                  label={i + 1 + ". mellemlanding"} 
                                                                  variant="outlined"
                                                  />} 
                                    />
                             </Collapse>
                            ))
                        }
                    </TransitionGroup>
                </Grid>

                <Grid item md={6} xs={12}>
                    <Button disabled={airports.length <= 1} variant='outlined' onClick={() => {
                        dispatch(removeLastAirport())
                        setAutocompOptions([])
                    }}>- fjern mellemlanding
                    </Button>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Button variant='contained' onClick={() => {
                        dispatch(addAirport())
                        setAutocompOptions([])
                    }}>+ tilføj mellemlanding
                    </Button>
                </Grid>
            </Grid>
           </FormContainer>
}

export default AddStopOver
