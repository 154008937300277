import React from 'react'
import { useAppDispatch } from '../../../hooks'
import BlurredDialog from "../../basic/BlurredDialog"
import Typography from "@mui/material/Typography"
import { setShowLessThanThreeHourDialog } from "../RadioButtonPages/ThreeHourSlice"
import { useAppSelector } from '../../../hooks'
import ReasonType from "../../../enums/ReasonType"

function LessThanThreeHourDialog(props:{}) {
    const dispatch = useAppDispatch()
    const { reason } = useAppSelector(state => state.reason)
    let hourText = "3"
    if (reason === ReasonType.Cancelled) {
        hourText = "2"
    }
    const { showLessThanThreeHourDialog } = useAppSelector(state => state.threeHour)
    return (
        <BlurredDialog
            title={'Du er desværre ikke er berettiget til kompensation'}
            open={showLessThanThreeHourDialog}
            handleClose={() => {dispatch(setShowLessThanThreeHourDialog(false))}}
        >
            <Typography>Du er desværre ikke berettiget til kompensation, da din forsinkelse er mindre end {hourText} timer.</Typography>
        </BlurredDialog>
    )
}

export default LessThanThreeHourDialog
