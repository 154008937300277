import React, { useRef, useEffect, useState, ReactNode, RefObject } from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'


interface FormContainerProps {
  children: ReactNode
  willScroll?: boolean
  isTransparent?: boolean
  boxShadow?: boolean
  marginTop?: number
}

const FormContainer = (props: FormContainerProps) => {
  const ref = useRef<null | HTMLDivElement>(null) // Is it bad to create a ref and not use it?
  const [isMounted, setIsMounted] = useState<boolean>(false)

  useEffect(() => {
    if (props.willScroll && !isMounted)
      if (ref.current !== null) ref.current.scrollIntoView({ behavior: "smooth" })
  })

  useEffect(() => {
      setIsMounted(true)
  }, [])

  if (props.isTransparent)
      return <Container ref={ref} sx={{ marginTop: props.marginTop }}>
              <Box sx={{
                // backgroundColor: '#e9ecfa',
                // borderRadius: 3,
                padding:3,
                marginBottom: 2,
                // boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
            }} 
              className="blurBox"
            >
                  {props.children}
           </Box>
          </Container>
  else
      return <Container ref={ref}>
              <Box sx={{
                // backgroundColor: '#e9ecfa',
                backgroundColor: '#fff',
                // borderRadius: 3,
                padding:3,
                marginBottom: 2,
                // boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                boxShadow: props.boxShadow ? "0 4px 10px rgba(0, 0, 0, 0.1)" : "none",
            }} 
              // className="blurBox"
            >
                  {props.children}
           </Box>
          </Container>
    
}

export default FormContainer
