import React from 'react';
import FormContainer from '../../basic/FormContainer'
import Button from '@mui/material/Button'
import { setReason } from "../../flow/Reason/ReasonSlice"
import ReasonType from "../../../enums/ReasonType"
import { useAppDispatch } from '../../../hooks'
import {Collapse, List, ListItem, Typography} from "@mui/material"

function ButtonContainer(props:{})  {
  const [checked, setChecked] = React.useState(false)
  const dispatch = useAppDispatch()
  const handleChange = () => {
    setChecked((prev) => !prev)
  };
  return <FormContainer isTransparent marginTop={-7}>
            <Typography variant={"h4"} marginBottom={2}>Tjek dit krav</Typography>
            <List>
              <ListItem>
                <Button size="large" href="/kompensation/aflyst"  style={{ fontSize: '20px', marginBottom: '5px', marginTop: '5px'}} onClick={()=>{dispatch(setReason(ReasonType.Cancelled))}} fullWidth variant="contained">Aflysning</Button>
              </ListItem>
              <ListItem>
                <Button size="large" href="/kompensation/forsinket" style={{ fontSize: '20px', marginBottom: '5px', marginTop: '5px'}} onClick={()=>{dispatch(setReason(ReasonType.Delayed))}} fullWidth variant="contained">Forsinkelse</Button>
              </ListItem>
              <ListItem>
                <Button size="large" href="/kompensation/boarding-aflysning"  style={{ fontSize: '20px', marginBottom: '5px', marginTop: '5px'}} onClick={()=>{dispatch(setReason(ReasonType.BoardingRejection))}} fullWidth variant="contained">Boardingafvisning</Button>
              </ListItem>
            </List>
         </FormContainer>
}

export default ButtonContainer
