import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Airport } from "../../../utils"

// Define a type for the slice state
interface RouteState {
  stopOverArray: Array<Airport | null>,

  isValidated: boolean,
  addStopOverContinue: boolean,
}

// Define the initial state using that type
const initialState: RouteState = {
  stopOverArray: [],
  isValidated: true,
  addStopOverContinue: false,
}

const isValidated = (state: RouteState) => 
     state.stopOverArray.every((x: Airport | null) => x !== null)
  || state.stopOverArray.length === 0

export const routeSlice = createSlice({
  name: 'addStopOver',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    initOrClearStopOvers: (state, action:PayloadAction<boolean>) => {
        if(action.payload) state.stopOverArray = [null]
        else state.stopOverArray = []
        state.isValidated = isValidated(state)
        state.addStopOverContinue = false
    },
    setIthAirport: (state, action:PayloadAction<{val: Airport | null, i:number}>) => {
        state.stopOverArray[action.payload.i] = action.payload.val;
        state.isValidated = isValidated(state)
        state.addStopOverContinue = false
    },
    setIsValidated: (state, action: PayloadAction<boolean>) => {
        state.isValidated = action.payload
    },
    removeLastAirport: (state) => {
        state.stopOverArray.pop();
        state.isValidated = isValidated(state)
        state.addStopOverContinue = false
    },
    addAirport: (state) => {
        state.stopOverArray.push(null);
        state.isValidated = isValidated(state)
        state.addStopOverContinue = false
    },
    setContinue: (state, action: PayloadAction<boolean>) => {
        state.addStopOverContinue = action.payload
    },
    
    clear: (state) => {
        state.addStopOverContinue = false
    }
  }
})

export const { addAirport,
               removeLastAirport,
               initOrClearStopOvers,
               setIthAirport,
               setIsValidated,
               setContinue,
               clear 
             } = routeSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default routeSlice.reducer
