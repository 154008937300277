import React from 'react'
import RouteInput from './RouteInput'

const RouteInfo = (props:{}) => {

    return <React.Fragment>
            <RouteInput />
           </React.Fragment>

}

export default RouteInfo
