import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Airport } from "../../../utils"

// Define a type for the slice state
interface RouteState {
  depAirport: Airport | null,
  arrAirport: Airport | null,

  isValidated: boolean,
}

// Define the initial state using that type
const initialState: RouteState = {
  depAirport: null,
  arrAirport: null,
  isValidated: false,
}

const isValidated = (state: RouteState) =>
   (state.depAirport !== null) && (state.arrAirport !== null)

export function isNonEUtoEU(state: RouteState): boolean {
  if (!state.depAirport || !state.arrAirport) return false;
  return !(state.depAirport.ec261) && (state.arrAirport.ec261);
}

export const routeSlice = createSlice({
  name: 'route',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setDeparture: (state, action: PayloadAction<Airport | null>) => {
        state.depAirport = action.payload
        state.isValidated = isValidated(state)
    },
    setArrival: (state, action: PayloadAction<Airport | null>) => {
        state.arrAirport = action.payload
        state.isValidated = isValidated(state)
    },
    setIsValidated: (state, action: PayloadAction<boolean>) => {
        state.isValidated = action.payload
    },
    clear: (state) => {} // Set to do nothing, so as not to cause clearing of decendending pages
  }
})

export const { setArrival,
               setDeparture,
               setIsValidated,
               clear
             } = routeSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default routeSlice.reducer
