import React, { useEffect } from "react"
import RadioButtonPage from "../../basic/RadioButtonPage"
import * as IsPresent from "./IsPresentSlice"
import * as DelayMessage from "./DelayMessageSlice"
import * as ThreeHour from "./ThreeHourSlice"
import DelayMsgAnswer from "../../../enums/DelayMsgAnswer"
import * as CancellationButInformed from "./CancellationButInformedSlice"
import { setAllFlights, setIndex } from "../ChooseFlight/ChooseFlightSlice"
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { Airport } from "../../../utils"
import ReasonType from "../../../enums/ReasonType"
import { Answer } from "../../../enums/utils"

import { useAppSelector, useAppDispatch } from "../../../hooks"

import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import { Tooltip } from "@mui/material"

export const ThreeHourPage = 
  () => {
  const { arrAirport } = useAppSelector(state => state.route)
  const { reason } = useAppSelector(state => state.reason)

  return <RadioButtonPage
          name="ThreeHourPage"
          text={`Hvor mange timer forsinket ankom du til ${arrAirport === null ? "endelige destination?" : arrAirport.name}?`}
          getState={(state) => state.threeHour.answer}
          setAnswer={(x) => ThreeHour.setAnswer(x as ThreeHour.Answer)}
          data={[
            { key: ThreeHour.Answer.LessThanTwo, val: reason === ReasonType.Cancelled ? "Mindre end 2 timer" : "Mindre end 3 timer" }
          , { key: ThreeHour.Answer.BetweenTwoOrFour,  val: reason === ReasonType.Cancelled ? "Mellem 2 og 4 timer" : "Mellem 3 og 4 timer" }
          , { key: ThreeHour.Answer.MoreThanFour, val: "Mere end 4 timer" }
          , { key: ThreeHour.Answer.NeverArrived, val: "Aldrig ankommet" }
          ]} 
          />
}

export const IsPresentPage = 
  () => <RadioButtonPage  
         name="IsPresentPage"
         text="Var du til stede ved gaten til boarding?"
         getState={(state) => state.isPresent.answer}
         setAnswer={(x) => IsPresent.setAnswer(x as Answer)}
         data={[
           {key: Answer.Yes, val: "Ja"}
         , {key: Answer.No, val: "Nej"}
         ]} />

export const DelayMessagePage = () => {
  const reason = useAppSelector(state => state.reason.reason)

  return <RadioButtonPage 
     name="DelayMessagePage" 
     text={`Hvornår blev du informeret om ${(reason === ReasonType.Delayed) ? "forsinkelsen" : "aflysningen"}?`}
     getState={(state) => state.delayMessage.answer}
     setAnswer={(x) => DelayMessage.setAnswer(x as DelayMsgAnswer)}
     data={[
      //  { key: DelayMsgAnswer.LessThanSevenDays, val: "Mindre end 7 dage før planlagt afgang (fx hvis du først blev informeret på flyvedagen)" }
       { key: DelayMsgAnswer.LessThanSevenDays, val: <React.Fragment> Under 7 dage før afgang <Tooltip enterTouchDelay={0} title="fx hvis du først blev informeret på flyvedagen"><HelpOutlineIcon fontSize="small"/></Tooltip> </React.Fragment>}
     , { key: DelayMsgAnswer.BetweenSevenAndFourteen, val: "7-14 dage før afgang" }
     , { key: DelayMsgAnswer.MoreThanFourteen, val: "Mere end 14 dage før afgang" }
     , { key: DelayMsgAnswer.NoMessage, val: `Fik aldrig besked om ${(reason === ReasonType.Delayed) ? "forsinkelsen" : "aflysningen"}` }
     ]}
     />
}

export const CancellationButInformedPage =
  () => <RadioButtonPage
         name="CancellationButInformedPage"
         text="Tilbød flyselskabet ombookning af rejsen eller refusion af billetprisen?"
         getState={(state) => state.cancellationButInformed.answer} 
         setAnswer={(x) => CancellationButInformed.setAnswer(Number(x))}
         data={[
           { key: CancellationButInformed.Answer.Yes, val: "Ja" }
         , { key: CancellationButInformed.Answer.No, val: "Nej" }
         ]}
         />

// FIXME: Perhaps add something if there are no airports or they are all null
export const ChooseFlightPage = () => {
    const dispatch = useAppDispatch()
    const { depAirport, arrAirport } = useAppSelector(state => state.route)
    let stopOverArray: Array<any> = useAppSelector(state => state.addStopOver.stopOverArray)
    const reason = useAppSelector(state => state.reason.reason)

    stopOverArray = [depAirport, ...stopOverArray, arrAirport]
    
    let airports: Array<{key: {from: Airport, to: Airport}, val: JSX.Element}> = 
      stopOverArray
      .map((x: Airport, i: number) => 
      {
          if (i === 0) return { from: x, to: stopOverArray[i + 1] }
          else return { from: stopOverArray[i-1], to: x }
      })
      .map((x: { from: Airport, to: Airport }) => 
          { 
              return { key: {from: x.from, to: x.to}, val: <span>{x.from.name} <ConnectingAirportsIcon /> {x.to.name}</span> }
          })
    
    airports = airports.slice(1)
    
    useEffect(() => {
      dispatch(setAllFlights(airports.map(x => x.key)))
    })
    
    const renderAirports = airports
        .map((x: { key: { from: Airport, to: Airport }, val: JSX.Element }, i: number) => { return { key: i, val: x.val } })
    
    const reasonToStr = (reason: ReasonType | null) => {
        switch(reason) {
            case ReasonType.Delayed: return "forsinket"
            case ReasonType.BoardingRejection: return "du afvist fra at boarde"
            default: return "aflyst"
        }
    }
    
    return <RadioButtonPage 
             name="ChooseFlightPage"
             text={`Hvilken flyvning blev ${reasonToStr(reason)}?`}
             getState={state => state.chooseFlight.index}
             setAnswer={(x) => setIndex(Number(x))}
             data={renderAirports}
           />
}
