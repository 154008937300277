import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Airline } from '../../../enums/entities/Airline'

interface FirstCarrierState {
    airline: Airline | null,
    isValidated: boolean,
    firstCarrierContinue: boolean,
    showNonEUDialog: boolean
}

const initialState: FirstCarrierState = {
    airline: null,
    isValidated: false,
    firstCarrierContinue: false,
    showNonEUDialog: true
}

function validate(state: FirstCarrierState): boolean {
    return state.airline !== null
}

export const firstCarrierSlice = createSlice({
    name: 'firstCarrier',
    initialState,
    reducers: {
        setAirline: (state, action: PayloadAction<Airline>) => {
            state.airline = action.payload
            state.isValidated = validate(state)
        },
        setContinue: (state, action: PayloadAction<boolean>) => {
            state.firstCarrierContinue = action.payload
        },
        setShowNonEUDialog: (state, action: PayloadAction<boolean>) => {
            state.showNonEUDialog = action.payload
        },
        clear: (state) => {
            state.airline = null
            state.isValidated = false
            state.firstCarrierContinue = false
            state.showNonEUDialog = true
        }
    }
})

export const { setAirline, setContinue, setShowNonEUDialog, clear } = firstCarrierSlice.actions

export default firstCarrierSlice.reducer 