import React, { useState, ChangeEvent, SyntheticEvent, useEffect } from 'react'
import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { RootState } from "../../../store"
import {TextField, Grid, Autocomplete, InputAdornment} from '@mui/material'
import FormContainer from '../../basic/FormContainer'
import { useAppDispatch } from '../../../hooks'
import { setArrival, setDeparture } from './RouteSlice'
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff'
import FlightLandIcon from '@mui/icons-material/FlightLand'
import { Airport } from "../../../utils"
import HeaderTypography from "../../basic/HeaderTypography"
import { useAppSelector } from '../../../hooks'
import { setEnd } from "../CompositionPage/CompositionSlice"
import { getClaimAmount } from "../../../requests";

import { searchAirports } from "../../../requests"

export interface AutocompleteOption {
    name: string,
    iata: string,
    country: string,
    city: string,
    countryCode: string,
    ec261: boolean
}

type HandleAutcomFunc = (d: Array<AutocompleteOption>) => void
type HandleAirportFunc = (airport: Airport | null) => AnyAction // (airport: Airport | null) => ThunkAction<void, RootState, unknown, AnyAction>

const RouteInput = (props:{}) => {
    const [depAutocompOptions, setDepAutocompOptions] = useState<Array<AutocompleteOption>>([])
    const [arrAutocompOptions, setArrAutocompOptions] = useState<Array<AutocompleteOption>>([])
    // The `state` arg is correctly typed as `RootState` already
    const dispatch = useAppDispatch()

    const {depAirport, arrAirport} = useAppSelector(state => state.route)

    useEffect(() => {
      if (depAirport && arrAirport) {
        getClaimAmount(depAirport.iata, arrAirport.iata).then((res: any) => {
          if (res.ok) {
            dispatch(setEnd(res.val))
          }
        })
      }
    }, [depAirport, arrAirport, dispatch])

    const handleAirport = (f: HandleAirportFunc) => (e: object, value: AutocompleteOption | null) => {
      if (value !== null) {
        const airport: Airport = { name: value.name, iata: value.iata, ec261: value.ec261, countryCode: value.countryCode }
        dispatch(f(airport)) 
      } else {
        dispatch(f(null)) 
      }
    }

    const handleAutocompReq = (f: HandleAutcomFunc) => async (e: object) => {
        // @ts-ignore
        const data = await searchAirports(e.target.value)
        if (data.ok){
            f(data.val)
        }
    }

    return <FormContainer willScroll>
            <HeaderTypography>Indtast hele din planlagte rejse</HeaderTypography>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Autocomplete 
                  options={depAutocompOptions}
                  noOptionsText={"Søg efter en lufthavn"}
                  data-testid="routeinfo-depature-autocomplete"
                  // loading={true}
                  onChange={handleAirport(setDeparture)}
                  filterOptions={(x) => x}
                  onInputChange={handleAutocompReq(setDepAutocompOptions)}
                  getOptionLabel={(option) => `${option.name} (${option.iata}), ${option.city}, ${option.country}`}
                  renderInput={(params) => <TextField {...params} 
                                              label="Afgangslufthavn"
                                                      InputProps={{
                                                          ...params.InputProps,
                                                          startAdornment: (
                                                              <InputAdornment position="start">
                                                                  <FlightTakeoffIcon />
                                                              </InputAdornment>
                                                          )
                                                      }}
                                              variant="outlined"
                              />}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Autocomplete 
                  options={arrAutocompOptions}
                  filterOptions={(x) => x}
                  noOptionsText={"Søg efter en lufthavn"}
                  data-testid="routeinfo-arrival-autocomplete"
                  onChange={handleAirport(setArrival)}
                  onInputChange={handleAutocompReq(setArrAutocompOptions)}
                  getOptionLabel={(option) => `${option.name} (${option.iata}), ${option.city}, ${option.country}`}
                  renderInput={(params) => <TextField {...params}
                                              label="Endelig destination"
                                                      InputProps={{
                                                          ...params.InputProps,
                                                          startAdornment: (
                                                              <InputAdornment position="start">
                                                                  <FlightLandIcon />
                                                              </InputAdornment>
                                                          )
                                                      }}
                                              variant="outlined"
                              />} 
                />
              </Grid>
            </Grid>
           </FormContainer>
}

export default RouteInput
