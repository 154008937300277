import React from 'react'
import { useAppDispatch } from '../../../hooks'
import BlurredDialog from "../../basic/BlurredDialog"
import Typography from "@mui/material/Typography"
import { setShowNonEUDialog } from "../FirstCarrierPage/FirstCarrierSlice"
import { useAppSelector } from '../../../hooks'

function NonEUToEUWithNonEUAirlineDialog(props:{}) {
    const dispatch = useAppDispatch()
    const { showNonEUDialog } = useAppSelector(state => state.firstCarrier)
    return (
        <BlurredDialog
            title={'Du er desværre ikke berettiget til kompensation'}
            open={showNonEUDialog}
            handleClose={() => {dispatch(setShowNonEUDialog(false))}}
        >
            <Typography>Da din flyvning ikke startede i et EU-land og ikke blev udført med et flyselskab fra et EU-land, er du ikke berettiget til kompensation</Typography>
        </BlurredDialog>
    )
}

export default NonEUToEUWithNonEUAirlineDialog
