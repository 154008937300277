import React, { useState } from 'react'
import { Autocomplete, TextField, Collapse, Button } from '@mui/material'
import FormContainer from '../../basic/FormContainer'
import { setAirline, setContinue } from "./FirstCarrierSlice"
import { useAppSelector, useAppDispatch } from '../../../hooks'
import { searchAirlines } from "../../../requests"
import HeaderTypography from "../../basic/HeaderTypography"
import { Airline } from '../../../enums/entities/Airline'

interface AutocompleteOption {
    name: string,
    iata: string,
    id: string,
    ec261: boolean
}

function FirstCarrierPage() {
    const { airline, isValidated, firstCarrierContinue } = useAppSelector(state => state.firstCarrier)
    const [autocompOptions, setAutocompOptions] = useState<Array<AutocompleteOption>>([])
    const [loading, setLoading] = useState<boolean>(false)
    const dispatch = useAppDispatch()

    const handleAirline = (e: object, value: AutocompleteOption | null) => {
        if (value !== null) {
            const airline: Airline = { name: value.name, iata: value.iata, id: value.id, ec261: value.ec261 }
            dispatch(setAirline(airline))
        } else {
            // dispatch(setAirline(null))
        }
    }

    const handleAutocompReq = async (e: object) => {
        setLoading(true)
        // @ts-ignore
        const data = await searchAirlines(e.target.value)
        if (data.ok)
            setAutocompOptions(data.val)
        setLoading(false)
    }

    const handleContinue = () => { 
        dispatch(setContinue(isValidated)) 
    }

    return <React.Fragment>
             <FormContainer willScroll>
               <HeaderTypography>Hvilket flyselskab stod for første flyvning?</HeaderTypography>
               <Autocomplete
                 options={autocompOptions}
                 noOptionsText={"Søg efter et flyselskab"}
                 data-testid="firstCarrier-autocomplete"
                 fullWidth
                 loading={loading}
                 onChange={handleAirline}
                 onInputChange={handleAutocompReq}
                 getOptionLabel={(option) => `${option.name} (${option.iata})`}
                 renderInput={(params) => <TextField {...params}
                                             label="Flyselskab"
                                             variant="outlined"
                                             />}
               />
             </FormContainer>
           </React.Fragment>
}

export default FirstCarrierPage 