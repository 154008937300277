import React, { ReactNode } from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    backdropFilter: "blur(5px)",
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

export const BootstrapDialogTitle = React.forwardRef<HTMLDivElement, DialogTitleProps>((props, ref) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other} ref={ref}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
});

BootstrapDialogTitle.displayName = 'BootstrapDialogTitle';

const BlurredDialog = React.forwardRef((
    props: {
        title: string, 
        children: ReactNode, 
        open: boolean, 
        handleClose: () => void, 
        noOkButton?: boolean,
        onRendered?: () => void
    }, 
    ref: React.ForwardedRef<HTMLDivElement>
) => {
    return (
        <div>
            <BootstrapDialog
                onClose={props.handleClose}
                aria-labelledby="customized-dialog-title"
                scroll={"paper"}
                open={props.open}
                TransitionProps={{
                    onEntered: props.onRendered
                }}
            >
                <BootstrapDialogTitle 
                    id="customized-dialog-title" 
                    onClose={props.handleClose}
                    ref={ref}
                >
                    {props.title}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    {props.children}
                </DialogContent>
                {
                    !props.noOkButton &&
                    <DialogActions>
                        <Button autoFocus onClick={props.handleClose}>
                            OK
                        </Button>
                    </DialogActions>
                }
            </BootstrapDialog>
        </div>
    );
});

BlurredDialog.displayName = 'BlurredDialog';

export default BlurredDialog;
