import React from 'react'
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import BlurredDialog from "../../basic/BlurredDialog"
import Typography from "@mui/material/Typography"
import Box from '@mui/material/Box'
import { useAppSelector, useAppDispatch } from '../../../hooks'
import { setOpen } from './ConfettiSlice'

const ConfettiPage: React.FC = () => {
    const { width, height } = useWindowSize()
    const dispatch = useAppDispatch()
    const isOpen = useAppSelector(state => state.confetti.isOpen)
    const [dialogTop, setDialogTop] = React.useState(0)
    const [showConfetti, setShowConfetti] = React.useState(false)
    
    const dialogRef = React.useRef<HTMLDivElement>(null)

    React.useEffect(() => {
        console.log('isOpen', isOpen)
        console.log('dialogRef', dialogRef)
        if (isOpen && dialogRef.current) {
            setShowConfetti(false)
            setTimeout(() => {
                const rect = dialogRef.current?.getBoundingClientRect()
                if (rect) {
                    setDialogTop(rect.top)
                    setShowConfetti(true)
                }
            }, 100)
        }
    }, [isOpen])

    const handleClose = () => {
        dispatch(setOpen(false))
    }

    const handleDialogRendered = () => {
        const rect = dialogRef.current?.getBoundingClientRect()
        if (rect) {
            setDialogTop(rect.top)
            setShowConfetti(true)
        }
    }

    return (
        <BlurredDialog
            title={'Tillykke!🎉🎉🎉'}
            open={isOpen}
            handleClose={handleClose}
            ref={dialogRef}
            onRendered={handleDialogRendered}
        >
            <Box display="flex" flexDirection="column" alignItems="center">
                {isOpen && showConfetti && (
                    <Box sx={{ position: 'fixed', top: 0, left: 0, zIndex: 9999 }}>
                        <Confetti
                            width={width}
                            height={height}
                            numberOfPieces={200}
                            recycle={false}
                            confettiSource={{
                                x: width / 2,
                                y: dialogTop,
                                w: 0,
                                h: 0
                            }}
                        />
                    </Box>
                )}
                {/* <Typography variant="h6" align="center" gutterBottom> */}
                <Typography align="center" gutterBottom>
                    Det ser ud til, at du er berettiget!                
                </Typography>
                <Typography align="center" gutterBottom>
                    Udfyld resten af formularen for at oprette din sag.
                </Typography>
            </Box>
        </BlurredDialog>
    )
}

export default ConfettiPage
