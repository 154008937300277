import React, { ChangeEvent } from 'react'
import FormContainer from '../../basic/FormContainer'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { FormControl, FormControlLabel, RadioGroup, Radio, Collapse, Button, Divider } from '@mui/material'
import { initOrClearStopOvers, setContinue } from './AddStopOverSlice'
import HeaderTypography from "../../basic/HeaderTypography"
import AddStopOver from "./AddStopOver"
import TagManager from 'react-gtm-module'

const convertStringToBool = (x:string) => {
    if (x ==="yes") return true;
    return false;
}

const AskStopOver = (prop:{}) => {
    const dispatch = useAppDispatch();
    const airports = useAppSelector(state => state.addStopOver.stopOverArray)
    const { isValidated, addStopOverContinue } = useAppSelector(state => state.addStopOver)

    const handleAddStopOverContinue = () => {
        TagManager.dataLayer({
          dataLayer: {
            event: "addStopOver_continue",
          }
        })
        dispatch(setContinue(isValidated))
    }

    return <>
             <FormContainer willScroll>
               <HeaderTypography>Var der mellemlandinger på rejsen?</HeaderTypography>
               <FormControl>
                 <RadioGroup
                   defaultValue="no"
                   row
                   onChange={(e: ChangeEvent<HTMLInputElement>) => { dispatch(initOrClearStopOvers(convertStringToBool(e.target.value))) }}
                   name="radio-buttons-group"
                 >
                   <FormControlLabel value="yes" control={<Radio />} label="Ja" />
                   <FormControlLabel value="no" control={<Radio />} label="Nej" />
                 </RadioGroup>
               </FormControl>
               <Collapse in={airports.length > 0}>
                 <AddStopOver />
               </Collapse>
             </FormContainer>
              <Collapse in={!addStopOverContinue && isValidated}>
                <FormContainer>
                  <Button variant="contained"
                          onClick={handleAddStopOverContinue}>
                    Fortsæt
                  </Button>
                </FormContainer>
              </Collapse>
           </>
}

export default AskStopOver
