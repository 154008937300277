import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Define a type for the slice state
interface ConfettiState {
    isOpen: boolean
}

// Define the initial state using that type
const initialState: ConfettiState = {
    isOpen: true
}

export const confettiSlice = createSlice({
    name: 'confetti',
    initialState,
    reducers: {
        setOpen: (state, action: PayloadAction<boolean>) => {
            state.isOpen = action.payload
        },
        clear: (state) => {
            state.isOpen = true
        }
    }
})

export const { setOpen, clear } = confettiSlice.actions

export default confettiSlice.reducer 